










































































































































import Vue from 'vue';
import LearningPathUnitResourceViewModel from '@/src/services/viewModel/resource/LearningPathUnitResourceViewModel';
import LearningPathUnitListResourceViewModel from '@/src/services/viewModel/resource/LearningPathUnitListResourceViewModel';
import GetLearningPathUnitByLearningPathPagedListRequestViewModel from '@/src/services/viewModel/request/LearnitPaths/GetLearningPathUnitByLearningPathPagedListRequestViewModel';
import LearningPathResourceViewModel from '@/src/services/viewModel/resource/LearningPathResourceViewModel';
import LearningProgressResourceViewModel from '@/src/services/viewModel/resource/LearningProgressResourceViewModel';

export default Vue.extend({
  auth: true,
  data: () => ({
    isLoading: false,
    isLoadingButton: false,
    learningContentDialogVisible: false,
    allLearningUnitsInPath: [] as any[],
    learningUnit: new LearningPathUnitResourceViewModel(),
    relatedLearningUnits: [] as LearningPathUnitListResourceViewModel[],
    picturePath: '/img/learnit-unit-default.png',
    learningPath: new LearningPathResourceViewModel(),
  }),
  computed: {
    isAdmin(): boolean {
      return this.$auth.isInRole('Admin', 'Hostadmin');
    },
    learningPathId(): number | undefined {
      const { learningPathId } = this.$router.currentRoute.params;
      if (learningPathId) {
        return parseInt(learningPathId, 10);
      }
      return undefined;
    },
    learningUnitId() {
      const { learningUnitId } = this.$router.currentRoute.params;
      if (learningUnitId) {
        return parseInt(learningUnitId, 10);
      }
      return undefined;
    },
  },
  async beforeMount() {
    this.isLoading = true;
    if (this.learningUnitId) {
      this.learningUnit = await this.$service.api.learnitUnits.getLearningPathUnitById(this.learningUnitId!);
      const response = await this.$service.api.learnitUnits.getSimilarLearningPathUnitList(this.learningUnitId!);
      this.relatedLearningUnits = response.items;
      if (this.learningPathId) {
        this.learningPath = await this.$service.api.learnitPaths.getLearningPathById(this.learningPathId);
        await this.loadLearningPathUnits();
      }
    }

    this.isLoading = false;
  },
  methods: {
    async loadLearningPathUnits() {
      const response = await this.$service.api.learnitPaths.getLearningPathUnitByLearningPathPagedList(this.learningPathId!, new GetLearningPathUnitByLearningPathPagedListRequestViewModel());
      this.allLearningUnitsInPath = response.items;
    },
    closeLearningUnit() {
      this.learningContentDialogVisible = false;
    },
    openLearningUnit() {
      this.learningContentDialogVisible = true;
    },
    async downloadLearningUnit() {
      this.isLoadingButton = true;
      try {
        const response = await this.$service.api.learnitUnits.downloadLearningPathUnitCertificate(this.learningUnitId!);
        const fileName = `Zertifikat-${this.learningUnit.title}.pdf`;
        this.$service.utils.downloadFile(fileName, response);
      } catch (error) {
        this.$log.error(error);
      }
      this.isLoadingButton = false;
    },
    getImage() {
      return this.$service.get('', {
        responseType: 'blob',
        baseURL: 'https://source.unsplash.com/featured?learning,teaching,computer',
        withCredentials: false,
      });
    },
    async startLearningUnit() {
      const user = await this.$auth.getUser();
      const o = new LearningProgressResourceViewModel();
      o.entityId = this.learningUnit.id;
      o.entityType = this.learningUnit.learningUnitEntityType;
      o.userId = user?.id!;
      if (this.learningUnit.learningProgress === undefined || this.learningUnit.learningProgress.statusPercentage !== 0) {
        await this.$service.api.learningProgress.setOrUpdateLearningProgress(o);
      }
      this.learningUnit = await this.$service.api.learnitUnits.getLearningPathUnitById(this.learningUnit.id!);
    },
    async completeLearningUnit() {
      const user = await this.$auth.getUser();
      const o = new LearningProgressResourceViewModel();
      o.entityId = this.learningUnit.id;
      o.entityType = this.learningUnit.learningUnitEntityType;
      o.userId = user?.id!;
      o.statusPercentage = 100;
      await this.$service.api.learningProgress.setOrUpdateLearningProgress(o);
      this.learningUnit = await this.$service.api.learnitUnits.getLearningPathUnitById(this.learningUnit.id!);
    },
    goToUnit(learningUnitId: number) {
      const path = `/ktgl/learnit/learningPaths/${this.learningPathId}/learningUnits/${learningUnitId}`;
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
  },
});
